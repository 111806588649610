
import "./billing.scss";
import Grid from '@material-ui/core/Grid';
import BarBilling from "../../components/chartjs/BarBilling";
import DoughnutBilling from "../../components/chartjs/DoughnutBilling";
import QueueList from "../../components/queueList/QueueList";
import Map from "../../components/map/Map";

export default function Billing(params) {

  return <div className="billing">
    <div className="top">
      <h1>Billing</h1>
      <span>Welcome back, Lucy! We've missed you. 👋</span>
    </div>
    <div className="body">
      <Grid container >
        <Grid item xs={12} sm={12} md={4}>
          <Grid container >
            <Grid item xs={12} sm={6}>
              <div className="section middle">
                <span>Ingresos</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="section middle">
                <span>Retiros</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="section middle">
                <span>Saldo Actual</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="section middle">
                <span>Follows</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <div className="section">
            <div>
              <span>Billings</span>
            </div>
            <div className="grafo">
              <BarBilling />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <div className="section">
            <div>
              <span>Map</span>
            </div>
            <div className="grafo">
              <Map />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <div className="section">
            <div>
              <span>Source / Medium</span>
            </div>
            <div className="grafo">
              <DoughnutBilling />
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <div className="section">
            <div>
              <span>Queue List</span>
            </div>
            <div className="grafo">
              <QueueList />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
}