import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  // plugins: {
  //   legend: {
  //     position: 'right',
  //   },
  // },
  layout: {
    padding: '20px 0px'
  }
}

const randomInteger = ({ min, max }) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function DoughnutBilling() {
  const data = {
    labels: ['Desktop', 'Mobile'],
    datasets: [
      {
        label: '# of Votes',
        data: [randomInteger({ min: 0, max: 100 }), randomInteger({ min: 0, max: 100 })],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut data={data} options={options} />;
}