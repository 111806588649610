

import ReactMapGL from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl'

// /* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires */
// mapboxgl.workerClass =require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default
// /* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires*/
export default function Map({ classes }) {
  return <>
    <ReactMapGL
      initialViewState={{
        longitude: -122.4,
        latitude: 37.8,
        zoom: 1
      }}
      style={{ width: '100%', height: '100%' }}
      mapStyle="mapbox://styles/mapbox/outdoors-v11"
      mapboxAccessToken={"pk.eyJ1IjoianBhbGFtb3MiLCJhIjoiY2t6dWcyY3VqN21jNzJ3bmtod2h6MTVhYiJ9.rzQjL0n10F8ekI_fFuboyA"}
    />
  </>
};