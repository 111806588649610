import Navbar from "../../components/navbar/Navbar";
import Featured from "../../components/featured/Featured";
import "./home.scss";
import { Tabs, Tab } from "@material-ui/core"
import ViewGeneral from "../../views/general/General";
import ViewBilling from "../../views/billing/Billing";
import ViewFollows from "../../views/follows/Follows";
import ViewPremiun from "../../views/premiun/Premiun";
import { useState } from "react";
import jwt_decode from "jwt-decode";

const styleTabs = { color: '#fff' }
// const styleTemp = {
//   color: '#fff',
//   minHeight: '300px',
//   textAlign: 'center'
// }

export default function Home({ type }) {
  const [valueTab, setValueTab] = useState(0)
  const decodedToken = jwt_decode(localStorage.getItem("user"));
  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };
  return (
    <div className="home">
      <Navbar isHome />
      <Featured type={type} />
      <Tabs value={valueTab}
        onChange={handleChangeTabs}
        aria-label="basic tabs example"
        // indicatorColor="secondary"
        centered
      >
        <Tab label="General" id={"simple-tab-1"} style={styleTabs} />
        <Tab label="Follows" id={"simple-tab-2"} style={styleTabs} />
        <Tab label="Premiun" id={"simple-tab-3"} style={styleTabs} />
        {decodedToken && [2, 3].includes(decodedToken.role) &&
          <Tab label="Billing" id={"simple-tab-0"} style={styleTabs} />
        }
      </Tabs>
      {valueTab === 0 && <ViewGeneral decodedToken={decodedToken} />}
      {valueTab === 1 && <ViewFollows decodedToken={decodedToken} />}
      {valueTab === 2 && <ViewPremiun decodedToken={decodedToken} />}
      {valueTab === 3 && <ViewBilling />}
    </div>
  );
};

