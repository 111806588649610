import axios from "axios";
export function getListGral(page) {
  return new Promise((resolve, reject) => {
    axios.get("list/general/" + page)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function getListByFollows(page) {
  return new Promise((resolve, reject) => {
    axios.get("list/follows/" + page)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}


export function getListByPremium(page) {
  return new Promise((resolve, reject) => {
    axios.get("list/premium/" + page)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function getListByUsername(username, page) {
  return new Promise((resolve, reject) => {
    axios.get(`list/username/${username}/${page}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function sendListReaction(listId, reactionId) {
  return new Promise((resolve, reject) => {
    axios.post(`list/reaction/${listId}/${reactionId}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function sendListComment(listId, data) {
  return new Promise((resolve, reject) => {
    axios.post(`list/comment/${listId}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function buyList(listId, data) {
  return new Promise((resolve, reject) => {
    axios.post(`list/buy/${listId}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}