import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './app.scss';
import { AuthContextProvider } from "./context/authContext/AuthContext";
import { ContextProvider } from './context/videoLiveContext/videoLiveContex';
ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ContextProvider>
        <App />
      </ContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

