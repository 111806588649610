import axios from "axios";
export function getBillingImports() {
  return new Promise((resolve, reject) => {
    axios.get("billing/mounts")
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function getBillingRegister() {
  return new Promise((resolve, reject) => {
    axios.get("billing/mountsRegister")
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}