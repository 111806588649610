
import "./logo.scss";
import React from "react";
import { Link } from "react-router-dom";
import ultimateLogo from "../../assets/images/intimate-logo.png"

export default function Logo({ color, height, fontSize }) {
  return (
    <Link to="/" style={{ textDecoration: 'none' }} >
      <span style={{
        display: "flex",
        alignItems: "center"
      }}>
        <img
          style={{ height: height ? height : "90px" }}
          className="logo"
          src={ultimateLogo}
          alt=""
        />
        <label
          translate="off"
          htmlFor=""
          style={{
            cursor: "inherit",
            color: color ? color : "white",
            fontSize: fontSize ? fontSize : "2.5em"
          }}>
          Melocoton
        </label>
      </span>
    </Link>
  )
}