import { useEffect, useContext } from 'react';
import Avatar from '../avatar/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import "./createVideoLive.scss";
import { SocketContext } from '../../context/videoLiveContext/videoLiveContex';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
export default function CreateVideoLive({ handleClose }) {
  const navigate = useNavigate();
  const decodedToken = jwt_decode(localStorage.getItem("user"));
  const {
    openMultimedia,
    myVideo,
    setStream,
    stream,
    createRoom,
    // closeCamera
  } = useContext(SocketContext);

  useEffect(() => {
    // console.log('inicioCamara')
    openMultimedia()
      .then((srcObject) => {
        myVideo.current.srcObject = srcObject;
        setStream(srcObject)
      })
      .catch((e) => console.error(e))
  }, []);

  const closeCamera = () => {
    console.log('closeCamera')
    console.log(myVideo, stream)
    myVideo.current.pause();
    myVideo.current.src = "";
    setStream(null)
    stream?.getTracks().forEach(e => {
      e.stop()
    });
  }
  const closePublicate = () => {
    closeCamera()
    if (handleClose) {
      handleClose()
    }
  }
  const publicate = () => {
    createRoom({ ...decodedToken });
    navigate("room/" + decodedToken.username);
  }

  return (<>
    <Avatar showName />
    <div className='createVideoLive'>
      <div className='zoneButtonTop'>
        <div className='left'>
          <button ><EditIcon />Edit all</button>
          <button ><AddPhotoAlternateIcon />Add more</button>
        </div>
        <div className='rigth'>
          <button onClick={closePublicate}><ClearIcon /></button>
        </div>
      </div>
      <div className='videoArea'>
        <video
          playsInline
          muted
          ref={myVideo}
          autoPlay
        />
      </div>
    </div>
    <div className="bottomsFotter">
      <button onClick={publicate}>
        Lest go!
      </button>
    </div>
  </>)
}