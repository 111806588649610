import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { getBillingRegister } from "../../apis/billing";


ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  ...registerables
);

// const randomInteger = ({ min, max }) => {
//   return Math.floor(Math.random() * (max - min + 1)) + min;
// }
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart',
    // },
  },
  layout: {
    padding: '20px 0px'
  }
};


export default function ChartBilling() {
  const [datasets, setdatasets] = useState([]);

  const [labels, setLabels] = useState([]);
  useEffect(() => {

    // let total = 0;
    // let labelV = labels.map(() => {
    //   let ingreso = randomInteger({ min: 0, max: 1000 });
    //   let egreso = randomInteger({ min: -total, max: 0 });
    //   total += ingreso + egreso;
    //   return { ingreso, total, egreso }
    // })

    getBillingRegister()
      .then((results) => {
        let total = 0;
        let dates = [...results.expenses.map(e => e.date), ...results.income.map(e => e.date)]
          .sort()
          .filter(function (value, index, array) {
            return array.indexOf(value) === index;
          });
        let labelV = dates.map((date) => {
          let egreso = results.expenses.find((exp) => exp.date === date)
          let ingreso = results.income.find((inc) => inc.date === date)
          total += ingreso ? Number(ingreso.mountCredit) : 0;
          total -= egreso ? Number(egreso.mountCredit) : 0;
          return {
            ingreso: ingreso ? Number(ingreso.mountCredit) : 0,
            egreso: egreso ? -(Number(egreso.mountCredit)) : 0,
            total
          }
        })
        setLabels(dates)

        setdatasets([
          {
            type: 'line',
            label: 'Saldo',
            borderColor: 'rgb(255, 255, 255)',
            borderWidth: 2,
            fill: 2,
            cubicInterpolationMode: 'monotone',
            data: labelV.map((e) => e.total),
          },
          {
            type: 'bar',
            label: 'Ingresos',
            backgroundColor: 'rgb(53, 162, 235)',//'rgb(75, 192, 192)',
            data: labelV.map((e) => e.ingreso),
            borderColor: 'white',
            borderWidth: 2,
            stack: 'Stack 0',
            borderRadius: 5,
          },
          {
            type: 'bar',
            label: 'Egresos',
            backgroundColor: '#ff358888',
            data: labelV.map((e) => e.egreso),
            stack: 'Stack 0',
            borderRadius: 5,
          }
        ])
      })
      .catch((e) => console.log(e))
  }, [])

  return <Chart type='bar'
    data={{
      labels: labels.map((e) => e.split('T')[0]),
      datasets
    }}
    options={options} />;
}