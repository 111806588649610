import { useState, useEffect } from 'react';
import Avatar from '../avatar/Avatar';
import { useDropzone } from 'react-dropzone';
import { sendMultimedias } from '../../apis/multimedia';
import { getBillingImports } from '../../apis/billing';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import Swal from 'sweetalert2';
import "./createPublication.scss";

export default function CreatePublication({ handleClose }) {
  const [loadin, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [description, setDescription] = useState('');
  const [importe, setImporte] = useState(0)
  const [progressBar, setProgressBar] = useState(null)
  const [importes, setImportes] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    disabled: loadin,
    accept: 'image/jpeg, image/png, video/mp4',
    onDropAccepted: (acceptedFiles) => {
      setFiles(prevFile => [...prevFile, ...acceptedFiles].map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    },
    onDropRejected: () => {
      setFiles([]);
      alert('Only 10 or less allowed')
    }
  });
  useEffect(() =>
    () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [files]);

  useEffect(() => {
    getBillingImports()
      .then((results) => {
        setImportes(results)
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'))
  }, [])
  const closePublicate = () => {
    setDescription('')
    setFiles([])
    if (handleClose) {
      handleClose()
    }
  }
  const publicate = () => {
    if (importe && description && files.length > 0) {
      setLoading(true);
      sendMultimedias({ description, importe }, files, setProgressBar)
        .then((result) => {
          closePublicate()
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    } else {
      alert('incomplete form')
    }
  }
  return (<>
    <Avatar showName />
    <div className='createPublication'>
      <div className="import">
        <select disabled={loadin} name="import" id="import" placeholder='incoming' onChange={(e) => setImporte(e.target.value)}>
          <option disabled seleted value={null}>Incoming...</option>
          <option value={0}>Free</option>
          {importes.map((importe, index) => (
            <option key={'import_' + index} value={importe.importeId}>{`$ ${importe.mountCredit} credits`}</option>
          ))}
        </select>
      </div>
      <div className='titleList'>
        <textarea
          placeholder='Description'
          onKeyUp={(e) => setDescription(e.target.value)}
          disabled={loadin} />
      </div>
      <div className='zoneButtonTop'>
        <div className='left'>
          {files.length > 0 && <>
            <button disabled={loadin}><EditIcon />Edit all</button>
            <button disabled={loadin}><AddPhotoAlternateIcon />Add more</button>
          </>}
        </div>
        <div className='rigth'>
          <button disabled={loadin} onClick={closePublicate}><ClearIcon /></button>
        </div>
      </div>
      <div {...getRootProps({
        className: 'dropzone',
      })}>
        <input {...getInputProps()} />
        {files.length === 0 ? <>
          <p>Drag and drop images or videos here, or click to select files</p>
        </> : <>
          <div className='preview'>
            {files.map((file, index) => (
              <div key={`dropped_${index}`} className='dropped'>
                {['image/jpeg', 'image/png'].includes(file.type) &&
                  <img src={file.preview} alt="" />
                }
                {['video/mp4'].includes(file.type) &&
                  <video src={file.preview} playsInline webkit-playsInline alt="" />
                }
              </div>
            ))}
          </div>
        </>}
      </div>
      {loadin === true && <>
        <LinearProgress variant="determinate" value={progressBar} />
        <div className='linearProgress'>
          <CircularProgress size={11} />
          <span> Progress {progressBar}%</span>
        </div>
      </>}
      <div className="bottomsFotter">
        <button
          disabled={loadin}
          onClick={publicate}>
          {loadin && <CircularProgress size={10} />}
          <span>
            {loadin ? 'Uploading...' : 'publicate'}
          </span>
        </button>
      </div>
    </div>
  </>)
}