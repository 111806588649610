
import InfiniteScroll from "react-infinite-scroll-component";
import List from "../../components/list/List";
import { useEffect, useState } from "react";
import { getListByPremium } from "../../apis/list";
import Swal from 'sweetalert2';
export default function ViewPremiun({ decodedToken }) {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [lists, setLists] = useState([]);
  useEffect(() => {
    getListByPremium(page)
      .then((results) => {
        setLists((prevList) => prevList.concat(results.lists));
        setHasMore(page < results.length);
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'))
  }, [page]);
  return <>
    {lists.length > 0 ?
      <InfiniteScroll
        dataLength={lists.length}
        hasMore={hasMore}
        next={() => setPage((prevPage) => prevPage + 1)}
      // loader={<Spinner />}
      >
        {lists.map((list, index) => (
          <List key={index} list={list} isHome decodedToken={decodedToken} />
        ))}
      </InfiniteScroll>
      :
      <h1>Follows its here</h1>
    }
  </>
}