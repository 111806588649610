import React from "react";
import { AuthContext } from "./context/authContext/AuthContext";
import { logout } from "./context/authContext/AuthActions"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Home from "./pages/home/Home";
import Watch from "./pages/watch/Watch";
import Username from "./pages/username/Username";
import VideoChat from "./views/videoChat/VideoChat";
import Swal from 'sweetalert2';

export default function App() {
  const { user, dispatch } = React.useContext(AuthContext);

  axios.defaults.baseURL = process.env.REACT_APP_URL_API
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("user");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
  axios.interceptors.response.use((response) => {
    if (response.status === 401) {
      dispatch(logout())
      // alert("You are not authorized");
    }else{
      return response;
    }
  }, (error) => {
    console.error(error)
    if (error && error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    if (error && error.message && (error.message === 'Unauthorized' || error.status === 401)) {
      Swal.fire('Oops...', 'You are not authorized!', 'error')
      dispatch(logout())
    }
    if (error && error.message) {
      return Promise.reject(error.message);
    }
    return Promise.reject(error)
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
        <Route path="/register" element={!user ? <Register /> : <Navigate to="/" />} />
        <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
        <Route path="/watch/:multimediaId" element={<Watch />} />
        {/* <Route path="/live/" element={<Home type="movie" />} /> */}
        <Route path="/room/:username" element={<VideoChat />} />
        <Route path="/:username" element={<Username />} />
      </Routes>
    </BrowserRouter>
  );
};
