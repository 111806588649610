import { useEffect, useState } from "react"
import { /*Link,*/ useParams } from "react-router-dom";
import { getListByUsername } from "../../apis/list";
import { getFollows, postFollow } from "../../apis/follow"
import List from "../../components/list/List";
import InfiniteScroll from "react-infinite-scroll-component";
import Navbar from "../../components/navbar/Navbar";
import Swal from 'sweetalert2';

import "./username.scss";
import Avatar from "../../components/avatar/Avatar";
export default function Username() {
  const [lists, setLists] = useState([]);
  const [publicationLength, setPublicationLength] = useState(0)
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [addedFollow, setAddedFollow] = useState(0)
  const [follow, setFollow] = useState({
    follow: 0,
    followers: 0,
    followed: 0
  })
  const params = useParams();
  useEffect(() => {
    if (params?.username) {
      getFollows(params?.username)
        .then((follows) => {
          setFollow(follows)
        })
        .catch((err) => console.log(err))
    }
  }, [params.username])

  useEffect(() => {
    if (params?.username) {
      getListByUsername(params?.username, page)
        .then((results) => {
          setLists((prevList) => prevList.concat(results.lists));
          setPublicationLength(results.length)
          setHasMore(page < results.length);
        })
        .catch((err) => Swal.fire('Oops...', err, 'error'));
    }
  }, [params.username, page]);

  const setfollow = () => {
    postFollow(params.username)
      .then((e) => {
        setAddedFollow((a) => e.status === 1 ? a + 1 : a - 1)
        setFollow((f) => ({ ...f, followed: e.status }))
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'));
  }
  return (<>
    <Navbar />
    <div className="username">
      <div className="top">
        <div className="usernameTitle">
          <Avatar />
          <h3>{params?.username}</h3>
        </div>
        <div className="follow">
          <button onClick={() => setfollow()}>
            {follow.followed ? 'unfollow' : 'follow'}
          </button>
        </div>
      </div>
      <div className="title">
        <span><strong>{`${publicationLength} `}</strong>publications</span>
        <span><strong>{`${follow.follow} `}</strong>follow</span>
        <span><strong>{`${follow.followers + addedFollow} `}</strong>followers</span>
      </div>
      {/* <Featured type={type}  /> */}
      <InfiniteScroll
        dataLength={lists.length}
        hasMore={hasMore}
        next={() => setPage((prevPage) => prevPage + 1)}
      // loader={<Spinner />}
      >
        {lists.map((list, index) => (
          <List key={index} list={list} />
        ))}
      </InfiniteScroll>
    </div>
  </>
  );
}