import { useContext, useState, useLayoutEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { /*ArrowDropDown,*/ Notifications, Search } from "@material-ui/icons";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext/AuthContext";
import { logout } from "../../context//authContext/AuthActions";
import Logo from "../logo/Logo";
import jwt_decode from "jwt-decode";
import Avatar from "../avatar/Avatar";
import { Dialog, DialogContent } from '@material-ui/core';
import CreatePublicaction from "../createPublication/CreatePublication";
import CreateVideoLive from "../createVideoLive/CreateVideoLive"


// function useWindowSize() {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
// }

export default function Navbar({ isHome }) {

  // const [width, height] = useWindowSize();
  // console.log(width, height)

  const [openModal, setOpenModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { dispatch } = useContext(AuthContext);

  const decodedToken = jwt_decode(localStorage.getItem("user"));

  const handleClose = () => setOpenModal(false);
  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };
  return (<>
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      {/* <div className="container"> */}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={isHome ? 12 : 8} sm={isHome ? 3 : 6} md={isHome ? 3 : 6}>
          <Logo height={isScrolled ? '50px' : undefined} />
        </Grid>
        {!isScrolled && <>
          {isHome && <>
            <Grid item xs={12} sm={4} md={3} className="links">
              <Link to="/lives" className="link">Lives</Link>
              <Link to="/rooms" className="link">Rooms</Link>
              <Link to="/news" className="link">New and Popular</Link>
              <Link to="/myList" className="link">My List</Link>
            </Grid>
            {decodedToken && [2, 3].includes(decodedToken.role) && <Grid item xs={12} sm={3} md={3}>
              <div className="search">
                <Search className="icon" />
              </div>
              <div className="createButton">
                <button onClick={() => setOpenModal('createPublication')}>Create new publication</button>
                <button onClick={() => setOpenModal('createVideoLive')}>Create video live</button>
              </div>
            </Grid>
            }
          </>}
          <Grid item xs={isHome ? 12 : 4} sm={isHome ? 2 : 6} md={isHome ? 3 : 6} className="usernameInfo">
            <Notifications className="icon" style={{ verticalAlign: 'middle' }} />
            <Avatar showName />
            {/* <span className="profile"> */}
            {/* <ArrowDropDown className="icon" /> */}
            {/* <div className="options"> */}
            {/* <span>Settings</span> */}
            <div className="logout">
              <button onClick={() => dispatch(logout())} style={{ cursor: 'pointer' }}>Logout</button>
            </div>
            {/* </div> */}
            {/* </span> */}
            {/* </div> */}
          </Grid>
        </>}
      </Grid>
      {/* </div> */}
    </div>
    <Dialog
      open={openModal !== false}
      onClose={handleClose}
      aria-describedby="modal-publication"
      fullWidth
      disableEscapeKeyDown
      maxWidth={openModal === 'createVideoLive' ? 'xl' : 'md'}
    >
      <DialogContent>
        {openModal === 'createPublication' &&
          <CreatePublicaction
            handleClose={() => setOpenModal(false)}
          />
        }
        {openModal === 'createVideoLive' &&
          <CreateVideoLive
            handleClose={() => setOpenModal(false)}
          />
        }
      </DialogContent>
    </Dialog>
  </>

  );
};
