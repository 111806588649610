import "./listItem.scss";
import { useState } from "react";
import MultimediaInfo from "../multimediaInfo/MultimediaInfo";

export default function ListItem({ multimedia }) {
  const [isHovered, setIsHovered] = useState(false);
  // const [url, setUrl] = useState(null);
  // const [urlPoster, setUrlPoster] = useState(null);
  const token = localStorage.getItem("user");
  
  return (<>
    <div
      className="listItem"
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {multimedia?.multimediaId && multimedia?.mimetypeId === 1 ? <>
        <video className="poster"
          poster={`${process.env.REACT_APP_URL_API}/multimedia/poster/${multimedia?.multimediaId}?token=${token}`}
          src={`${process.env.REACT_APP_URL_API}/multimedia/video/${multimedia?.multimediaId}?token=${token}`}
          alt=""
          playsInline
          loop
          muted
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
          onMouseOver={event => event.target.readyState > 1 && event.target?.play()}
          onMouseOut={event => event.target?.pause()}
        />
        <div className="hover" style={{ display: isHovered ? 'block' : 'none' }}>
          <MultimediaInfo
            isModal={false}
            reactionId={multimedia?.multimediaReaction?.reactionId}
            multimedia={multimedia}
          />
        </div>
      </> : <>
        {multimedia?.multimediaId && <img
          className="poster"
          src={multimedia?.mimetypeId > 1 ? `${process.env.REACT_APP_URL_API}/multimedia/image/${multimedia?.multimediaId}?token=${token}` : ''}
          alt=""
        />}
      </>}
    </div>
  </>
  );
}
