import React from 'react'
import jwt_decode from "jwt-decode";
import "./Avatar.scss";

export default function Avatar({ showName }) {
  const decodedToken = jwt_decode(localStorage.getItem("user"));
  return (<>
    <img
      className='avatar'
      src="https://images.pexels.com/photos/6899260/pexels-photo-6899260.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
      alt=""
    />
    {showName && <>
      <span className='avatar'>{decodedToken && decodedToken.username ? ' ' + decodedToken.username : 'Username'}</span>
    </>}
  </>)
}