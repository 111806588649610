import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../context/authContext/apiCalls";
import { AuthContext } from "../../context/authContext/AuthContext";
import "./login.scss";
import Logo from "../../components/logo/Logo";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { /*user,isFetching,*/dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    login({ email, password }, dispatch);
  };
  const handleRegister = (e) => {
    e.preventDefault();
    navigate("/register");
  }
  return (
    <div className="login">
      <div className="top">
        <div className="wrapper">
          <Logo />
          <button className="button" onClick={handleRegister}>
            Register
          </button>
        </div>
      </div>
      <div className="container">
        <form>
          <h1>Sign In</h1>
          <input
            type="email"
            placeholder="username or email"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="button"
            onClick={handleLogin}
            disabled={!email || !password}
          >
            Sign In
          </button>
          <span>
            New to Intimate? <b>Sign up now.</b>
          </span>
          <small>
            This page is protected by Google reCAPTCHA to ensure you're not a
            bot. <b>Learn more</b>.
          </small>
        </form>
      </div>
    </div>
  );
}
