import { useEffect/*, useState */ } from "react";
import { ArrowBackOutlined } from "@material-ui/icons";
import { Link, useParams } from "react-router-dom";
// import { getMultimedia } from "../../apis/Multimedia";
import "./watch.scss";

export default function Watch() {
  const params = useParams();

  const token = localStorage.getItem("user");
  // const movie = location.movie;
  // const [url, setUrl] = useState(null)

  useEffect(() => {
    if (params?.multimediaId) {
      // getMultimedia(params.multimediaId).then((response) => {
      //   setUrl(URL.createObjectURL(new Blob([response], { type: response.type })));
      // })

      // getMultimedia_v2(params.multimediaId).then((response) => {
      //   setUrl(URL.createObjectURL(new Blob([response], { type: response.type })));
      // })
    }
  }, [params.multimediaId])
  return (
    <div className="watch">
      <Link to="/">
        <div className="back"><ArrowBackOutlined />Home</div>
      </Link>
      <video className="video"
        autoPlay={true}
        progress={"true"}
        controls
        playsInline
        muted
        loop
        webkit-playsInline
        // autoPlay
        src={`${process.env.REACT_APP_URL_API}/multimedia/video/${params?.multimediaId}?token=${token}`}
      />
    </div>
  );
}
