import React, { createContext, useState, useRef, useEffect } from 'react';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';

const SocketContext = createContext();

const socketUrl = `${window.location.origin}/events`;
const socket = io(socketUrl, {
  path: '/events',  // Especifica la ruta del socket
  secure: window.location.protocol === 'https:',
});

const ContextProvider = ({ children }) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [name, setName] = useState('');
  const [call, setCall] = useState({});
  const [me, setMe] = useState('');

  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const connectionRef = useRef(null);

  useEffect(() => {
    // socket.on('me', (id) => setMe(id));

    // socket.on('callUser', ({ from, name: callerName, signal }) => {
    //   setCall({ isReceivingCall: true, from, name: callerName, signal });
    // });


    // socket.on("connect_error", (err) => {
    // console.log(err);
    // console.error(err)
    // });
    return () => {
      socket.disconnect();
    };
  }, []);
  const openMultimedia = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.mediaDevices) {
        return reject('navigator dont found')
      }
      navigator.mediaDevices?.getUserMedia({
        video: {
          facingMode: 'user',
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 720, max: 1080 },
        },
        audio: true
      })
        .then((currentStream) => resolve(currentStream))
        .catch((err) => reject(err))
    })
  }

  const createRoom = (value) => {
    socket.emit('createRoom', value);
  }

  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on('signal', (data) => {
      socket.emit('answerCall', { signal: data, to: call.from });
    });

    peer.on('stream', (currentStream) => {
      // console.log(currentStream);
      userVideo.current.srcObject = currentStream;
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on('signal', (data) => {
      socket.emit('callUser', { userToCall: id, signalData: data, from: me, name });
    });

    peer.on('stream', (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on('callAccepted', (signal) => {
      setCallAccepted(true);
      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    setCallEnded(true);

    connectionRef.current.destroy();

    window.location.reload();
  };

  const sendMessageToRoom = (data) => {
    socket.emit('messageToRoom', data)
  }

  return (
    <SocketContext.Provider value={{
      call,
      callAccepted,
      myVideo,
      userVideo,
      stream,
      setStream,
      name,
      setName,
      callEnded,
      me,
      callUser,
      leaveCall,
      answerCall,
      openMultimedia,
      // closeCamera,
      createRoom,
      sendMessageToRoom
    }}
    >
      {children}
    </SocketContext.Provider>
  );
};


export { ContextProvider, SocketContext };