// import { useState } from "react";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import CreaterVideoChat from "./Creater";
export default function VideoChat() {
  const params = useParams();
  const decodedToken = jwt_decode(localStorage.getItem("user"));
  return <div className="videoChat">
    {params.username && decodedToken.username && params.username === decodedToken.username ? <>
      <CreaterVideoChat
        usernameRoom={params.username}
        username={params.username}
      />
    </> : <>
    </>}
  </div>
}