import axios from "axios";

export function getVideoRandom(type) {
  return new Promise((resolve, reject) => {
    axios.get(`multimedia/random${type ? "?type=" + type : ""}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function getGenders() {
  return new Promise((resolve, reject) => {
    axios.get(`multimedia/gender`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function getMultimedia_v2(filName) {
  return new Promise((resolve, reject) => {
    fetch(`multimedia/${filName}`, { responseType: 'stream' })
      .then((response) => { return response.ok ? response.json() : reject(response) })
      .then(res => resolve(res))
      .catch(err => reject(err));
  })
}

export function sendMultimedias(data, files, onProgress) {
  const formData = new FormData();
  formData.append('dataRequest', JSON.stringify(data));
  for (let file of files) {
    formData.append('files', file)
  }
  return new Promise((resolve, reject) => {
    // 'application/x-www-form-urlencoded'
    axios.post("multimedia/", formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        if (onProgress) {
          const { loaded, total } = progressEvent;
          const precentage = Math.floor((loaded * 100) / total);
          onProgress(precentage);
        }
      }
    })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function sendMultimediaReaction(multimediaId, reactionId) {
  return new Promise((resolve, reject) => {
    axios.post(`multimedia/reaction/${multimediaId}/${reactionId}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}