
import InfiniteScroll from "react-infinite-scroll-component";
import List from "../../components/list/List";
import { useEffect, useState } from "react";
import { getListGral } from "../../apis/list";
import Swal from 'sweetalert2';
export default function ViewGeneral({ decodedToken }) {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [lists, setLists] = useState([]);
  useEffect(() => {
    getListGral(page)
      .then((results) => {
        setLists((prevList) => prevList.concat(results.lists));
        setHasMore(page < results.length);
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'))
  }, [page]);
  return <>
    <InfiniteScroll
      dataLength={lists.length}
      hasMore={hasMore}
      next={() => setPage((prevPage) => prevPage + 1)}
    // loader={<Spinner />}
    >
      {lists.map((list, index) => (
        <List key={index} list={list} isHome decodedToken={decodedToken} />
      ))}
    </InfiniteScroll>
  </>
}