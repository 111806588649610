import { useState, useContext, useEffect } from 'react';
import Avatar from "../../components/avatar/Avatar";
import Grid from '@material-ui/core/Grid';
import { SocketContext } from '../../context/videoLiveContext/videoLiveContex';
import "./creater.scss";
export default function CreaterVideoChat({ usernameRoom, username }) {
  const [videoHeight, setVideoHeight] = useState(100);
  const [newMessage, setNewMessage] = useState('');
  const [message, setMessage] = useState([
    {
      avatar: '',
      username: 'username',
      text: 'aqui va un texto largo, tengo que mirar que mas pongo, aqui va un texto largo, tengo que mirar que mas pongo, aqui va un texto largo, tengo que mirar que mas pongo, aqui va un texto largo, tengo que mirar que mas pongo, aqui va un texto largo, tengo que mirar que mas pongo, ',
      idMessage: 0
    },
  ])

  const { myVideo, openMultimedia, setStream, sendMessageToRoom } = useContext(SocketContext);

  const report = (idMessage) => {
    console.log('report: ' + idMessage)
  }

  const sendMessage = () => {
    sendMessageToRoom({
      usernameRoom,
      username,
      text: newMessage,
    })
    setMessage(prevMessage => [...prevMessage, {
      avatar: '',
      username: 'username',
      text: newMessage,
      idMessage: 1
    }])
    setNewMessage('')
  }

  useEffect(() => {
    if (localStorage.getItem('stream') === 'true') {
      openMultimedia()
        .then((srcObject) => {
          myVideo.current.srcObject = srcObject;
          setStream(srcObject)
        })
        .catch((e) => console.error(e))
    }

  }, []);
  useEffect(() => {
    setVideoHeight(myVideo.current.clientHeight)
  }, [myVideo.current?.clientHeight])

  return <div className='createrVideoChat'>
    <div className="title">Algún título</div>
    <div className='info'></div>
    <div >
      <Grid container className='room'>
        <Grid item xs={12} sm={12} md={8} lg={8} className="camera">
          <video
            playsInline
            muted
            ref={myVideo}
            autoPlay
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} className="messages">
          <div className='messagesInput'>
            <input
              type="text"
              name="inputMessage"
              id="inputMessage"
              value={newMessage}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.key === 'Enter')
                  sendMessage()
              }}
              onChange={(e) => {
                setNewMessage(e.target.value)
              }} />
            <button
              type="submit"
              name="sendMessage"
              disabled={newMessage ? false : true}
              onClick={() => sendMessage()}>send</button>
          </div>
          <div className='messagesList' style={{ maxHeight: `calc(${videoHeight}px - 64px` }}>
            <ul>
              {message.map((e, index) => {
                return <li key={'messsage' + index}>
                  <div className="Avatar">
                    <Avatar avatar={e.avatar} />
                  </div>
                  <div className="content">
                    <div className="content-text">
                      <strong>{e.username}</strong>
                      <span>{e.text}</span>
                    </div>
                    <div className='content-report'>
                      <button onClick={() => report(e.idMessage)}>report</button>
                    </div>
                  </div>
                </li>
              })}
            </ul>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
}