import { useEffect, useState } from "react";
import { getQueueList } from "../../apis/queue";
import RefreshIcon from "@material-ui/icons/Refresh"
import { CircularProgress } from '@material-ui/core';
import "./queueList.scss";
export default function QueueList() {
  const [list, setList] = useState([])
  const [loading, setLoadin] = useState(false)
  const refreshList = () => {
    setLoadin(true)
    getQueueList()
      .then((e) => setList(e))
      .catch((e) => console.log(e))
      .finally(() => setLoadin(false))
  }
  useEffect(() => {
    refreshList()
  }, [])
  return <>
    <div className="refreshButton">
      <RefreshIcon onClick={refreshList} />
    </div>
    <ul className={'queueList'}>
      {loading ?
        <CircularProgress /> :
        list.map((item, index) => (
          <li key={`queueList_${index}`}>
            <div>{item.queue_status.statusQueue}</div>
            <div>{item.count}</div>
          </li>
        ))
      }
    </ul>
  </>
}