
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendRegister } from "../../apis/register";
import "./register.scss";
import Logo from "../../components/logo/Logo";
import Swal from 'sweetalert2';

export default function Register() {
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();
  const usernameRef = useRef();

  const handleStart = () => {
    if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailRef.current.value)){
      setEmail(emailRef.current.value.toLowerCase());
    }else{
      Swal.fire('Oops...', 'email incorrect', 'error')
    }
  };
  const handleSingIn = (e) => {
    e.preventDefault();
    navigate("/login");
  }
  const handleFinish = (e) => {
    e.preventDefault();
    // setPassword(passwordRef.current.value);
    // setUsername(usernameRef.current.value);

    sendRegister({ email, username: usernameRef.current.value.toLowerCase(), password: passwordRef.current.value })
      .then(results => {
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="register">
      <div className="top">
        <div className="wrapper">
          <Logo />
          <button className="button" onClick={handleSingIn}>
            Sign In
          </button>
        </div>
      </div>
      <div className="container">
        <h1>Unlimited content creators, shows and more, much more.</h1>
        <h2>Watch anywhere. Cancel anytime.</h2>
        <p>
          Ready to watch? Enter your email to create or restart your membership.
        </p>
        {!email ? (
          <div className="input">
            <input type="email" placeholder="email address" ref={emailRef} />
            <button className="registerButton" onClick={handleStart}>
              Get Started
            </button>
          </div>
        ) : (
          <form className="input">
            <input type="username" placeholder="username" ref={usernameRef} />
            <input type="password" placeholder="password" ref={passwordRef} />
            <button className="registerButton" onClick={handleFinish}>
              Start
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
