import axios from "axios";
export function getFollows(userId) {
  return new Promise((resolve, reject) => {
    axios.get(`follow/${userId}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}

export function postFollow(userId) {
  return new Promise((resolve, reject) => {
    axios.post(`follow/${userId}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  })
}