import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { getVideoRandom/*, getGenders, getMultimedia*/ } from "../../apis/multimedia";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import "./featured.scss";

export default function Featured({ type }) {
  const [content, setContent] = useState({});
  // const [genders, setGenders] = useState([]);
  // const [url, setUrl] = useState(null)
  const navigate = useNavigate();
  const token = localStorage.getItem("user");

  const getVideo = () => {
    getVideoRandom()
      .then((multimedia) => {
        if (multimedia?.multimediaId) {
          setContent(multimedia)
          // getMultimedia(multimedia.multimediaId).then((response) => {
          //   setUrl(URL.createObjectURL(new Blob([response], { type: response.type })));
          // })
        }
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'))
  }

  useEffect(() => {
    getVideo()
    if (type) {
      // getGenders()
      //   .then((genders) => {
      //     setGenders(genders)
      //   })
      //   .catch((err) => alert(err))
    }
  }, [type]);


  return (
    <div className="featured">
      {type && (
        <div className="category">
          <span>{type === "movies" ? "Movies" : "Series"}</span>
        </div>
      )}
      {[1].includes(content.mimetypeId) ? <>
        {content?.multimediaId && <video
          playsInline
          progress={"true"}
          controlsList="nodownload"
          controls
          muted
          autoPlay
          // src={`${process.env.REACT_APP_URL_API}/multimedia/${content?.multimediaId}/${token}`}
          src="https://carontestudio.com/img/f1.mp4"
          alt=""
          loop
          // type='video/mp4'
          onEnded={getVideo}
        />}
      </> : <>
        {content?.multimediaId && <img src={`${process.env.REACT_APP_URL_API}/multimedia/image/${content?.multimediaId}/${token}`} alt="" />}
      </>}
      <div className="info">
        <span className="title">{content.description}</span>
        {[1].includes(content.mimetypeId) ? <>
          {/* <video src={`${process.env.REACT_APP_URL_API}/multimedia/${content?.multimediaId}/${token}`} alt="" playsInline/> */}
        </> : <>
          {/* {content?.multimediaId && <img src={`${process.env.REACT_APP_URL_API}/multimedia/${content?.multimediaId}/${token}`} alt="" />} */}
        </>}
        <span className="desc">{content.description}</span>
        <div className="buttons">
          <button className="play" onClick={() => navigate("watch/" + content.multimediaId)}>
            <PlayArrow />
            <span>Play</span>
          </button>
          <button className="more">
            <InfoOutlined />
            <span>Info</span>
          </button>
        </div>
      </div>
    </div>
  );
}
