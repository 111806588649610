import "./list.scss";
import {
  // ArrowBackIosOutlined, ArrowForwardIosOutlined,
  ThumbUp,
  ThumbDown,
  Report,
  ReportOutlined,
  ThumbUpAltOutlined,
  ThumbDownOutlined,
} from "@material-ui/icons";
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import ListItem from "../listItem/ListItem";
import Avatar from "../avatar/Avatar";
import { sendListReaction, sendListComment, buyList } from "../../apis/list"
import { Dialog, DialogContent, CircularProgress/*, DialogActions, DialogTitle*/ } from '@material-ui/core';
// import  from '@material-ui/core/CircularProgress';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import Swal from 'sweetalert2';


export default function List({ list, isHome, decodedToken }) {
  const [openModal, setOpenModal] = useState(false);
  const [viewModel, setViewModel] = useState('')
  const [buyed, setBuyed] = useState(false);
  const [reaction, setReaction] = useState(null);
  const [reactionAdded, setReactionAdded] = useState(0);
  const [loadin, setLoading] = useState(false)
  const [comment, setComment] = useState('');
  const [selftComment, setSelftComment] = useState([])

  const listRef = useRef();
  const handleClose = () => setOpenModal(false)

  const handleReaction = (newReactionId) => {
    sendListReaction(list.listId, newReactionId)
      .then((result) => {
        setReaction(result.status === 1 ? newReactionId : null)
        setReactionAdded(result.status === 1 ? 1 : 0)
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'))
  }
  const publicateComment = () => {
    setLoading(true);
    sendListComment(list.listId, { comment })
      .then((result) => {
        setSelftComment(preComments => [...preComments, { comment, username: result.username }])
        handleClose()
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'))
      .finally(() => setLoading(false))
  }

  const handleBuyList = () => {
    setLoading(true);
    buyList(list.listId, null)
      .then((listBuyed) => {
        setBuyed(true)
        handleClose()
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'))
      .finally(() => setLoading(false))
  }

  const handleBuy = () => {
    setViewModel('payment')
    setOpenModal(true)
  }

  useEffect(() => {
    setReaction(list?.listReaction?.reactionId);
  }, [list?.listReaction?.reactionId])

  return (<>
    <div className="list">
      <div className="listTop">
        <Avatar />
        {isHome && <Link to={{ pathname: list.user?.username }}>
          <strong><span className="listTitle">{list.user?.username}</span></strong>
        </Link>
        }
        {!buyed ? !list.list_billingPayment && <>
          {list.list_billingImporte ?
            <div className="list-topCredits">
              <ShoppingCartTwoToneIcon />
              <span>${list.list_billingImporte.billing_importe.mountCredit} credits</span>
              {list.user?.username !== decodedToken?.username &&
                <button onClick={handleBuy}>Buy</button>
              }
            </div>
            :
            <button>it's free!!</button>
          }
        </>
          :
          <button>buyed!!</button>
        }
      </div>
      <div className="listDescription">
        <p>{list.description}</p>
      </div>
      <div className="wrapper">
        <div className="container" ref={listRef}>
          <Grid container spacing={2} justifyContent="center" alignItems="stretch">
            {list.listItems
              .filter((item) => [2, 3].includes(item.multimedia?.mimetypeId) || (item.multimedia?.mimetypeId === 1 && item.multimedia?.queue_multimediaPross?.statusQueueId === 6))
              .map((item, i) => (
                <Grid item key={`gridItem_${i}`}
                  xs={12}
                  sm={list.listItems.length === 1 ? 12 : 6}
                  md={list.listItems.length === 1 ? 10 : list.listItems.length < 4 ? 6 : 4}
                  lg={list.listItems.length === 1 ? 8 : list.listItems.length < 5 ? 4 : 3}
                >
                  <ListItem key={`listItem_${i}`}
                    // index={i}
                    multimedia={item.multimedia}
                    buyed={buyed || (list.list_billingImporte && list.list_billingPayment)}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
      <div className="listBottom">
        <div className="reactionsCounts">
          {(reactionAdded + list.listReactionCount) > 0 ?
            <h3>{reactionAdded + list.listReactionCount} reactions</h3>
            :
            <h3>No reactions yet</h3>
          }
        </div>
        <div className="icons">
          {reaction === 1 ? <ThumbUp className="icon active" onClick={() => handleReaction(1)} /> : <ThumbUpAltOutlined className="icon" onClick={() => handleReaction(1)} />}
          {reaction === 2 ? <ThumbDown className="icon active" onClick={() => handleReaction(2)} /> : <ThumbDownOutlined className="icon" onClick={() => handleReaction(2)} />}
          {reaction === 3 ? <Report className="icon active" onClick={() => handleReaction(3)} /> : <ReportOutlined className="icon" onClick={() => handleReaction(3)} />}
        </div>
      </div>
      <div className="listComments" onClick={() => {
        setViewModel('comment')
        setOpenModal(true)
      }}>
        {list.listCommentsCount > 0 ?
          <h3>See the {list.listCommentsCount} comments</h3>
          :
          <h3>No comments yet</h3>
        }
        {selftComment.map((item, i) => (
          <div key={'selfComment_' + i} className="coment">
            <strong>{item.username}</strong>
            <span>{item.comment}</span>
          </div>
        ))}
        {list.listComments.map((item, i) => (
          <div key={'comment_' + i} className="coment">
            <strong>{item.user?.username}</strong>
            <span>{item.comment}</span>
          </div>
        ))}
      </div>
    </div>
    <Dialog
      open={openModal}
      onClose={handleClose}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogContent>
        <div>
          <Avatar showName />
        </div>
        {viewModel === 'comment' && <>
          <div className="listInputArea">
            <textarea placeholder='Insert your comments' onKeyUp={(e) => setComment(e.target.value)} />
          </div>
          <div>
            <div className="bottomsFotter">
              <button
                disabled={loadin}
                onClick={publicateComment}>
                {loadin && <CircularProgress size={10} />}
                <span>
                  publicate
                </span>
              </button>
            </div>
          </div>
        </>}
        {viewModel === 'payment' && <>
          <div className="list-topCredits">
            <ShoppingCartTwoToneIcon />
            <span>${list.list_billingImporte.billing_importe.mountCredit} credits</span>
          </div>
          <div><p>{list.description}</p></div>
          <div className="bottomsFotter">
            <button className="btnClose" onClick={handleClose}>Close</button>
            <button onClick={handleBuyList}>Buy</button>
          </div>
        </>}
      </DialogContent>
    </Dialog>
  </>);
}
