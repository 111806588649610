import "./multimediaInfo.scss";
import {
  PlayArrow,
  Add,
  ThumbUp,
  ThumbDown,
  Report,
  ReportOutlined,
  ThumbUpAltOutlined,
  ThumbDownOutlined
} from "@material-ui/icons";
import Swal from 'sweetalert2';

import { Tooltip, Dialog/*, DialogActions, DialogContent, DialogTitle*/ } from '@material-ui/core';

import { useState, useEffect, useRef } from "react";
import { sendMultimediaReaction } from "../../apis/multimedia";

export default function MultimediaInfo({ isModal, reactionId, multimedia }) {
  const [openModal, setOpenModal] = useState(false);
  const [reaction, setReaction] = useState(null);
  const handleClose = () => setOpenModal(false)
  const token = localStorage.getItem("user");
  const refVideo = useRef()
  useEffect(() => {
    setReaction(reactionId);
  }, [reactionId])
  // useEffect(() => {
  //   console.log(refVideo?.current?.clientHeight)
  //   console.log(refVideo?.current?.clientWidth)
  //   if (refVideo?.current?.clientHeight) {

  //   }
  //   // console.log(refVideo?.current)
  //   //   // setHeight(ref.current.clientHeight)
  // }, [refVideo?.current?.clientHeight,])
  const handleModal = () => {
    setOpenModal(true)
  }
  const handleReaction = (newReactionId) => {
    sendMultimediaReaction(multimedia.multimediaId, newReactionId)
      .then((result) => {
        setReaction(result.status === 1 ? newReactionId : null)
      })
      .catch((err) => Swal.fire('Oops...', err, 'error'))
  }

  return <>
    <div className="itemInfo">
      <div className="icons">
        <Add className="icon" />
        {!isModal && <span onClick={handleModal} ><PlayArrow className="icon" /></span>}
        {reaction === 1 ?
          <Tooltip placement="top" title="like">
            <ThumbUp className="icon active" onClick={() => handleReaction(1)} />
          </Tooltip> : <Tooltip placement="top" title="I do not like it">
            <ThumbUpAltOutlined className="icon" onClick={() => handleReaction(1)} />
          </Tooltip>
        }
        {reaction === 2 ?
          <Tooltip placement="top" title="dislike">
            <ThumbDown className="icon active" onClick={() => handleReaction(2)} />
          </Tooltip> : <Tooltip placement="top" title="I do not dislike">
            <ThumbDownOutlined className="icon" onClick={() => handleReaction(2)} />
          </Tooltip>
        }
        {reaction === 3 ?
          <Tooltip placement="top" title="isFake">
            <Report className="icon active" onClick={() => handleReaction(3,)} />
          </Tooltip> : <Tooltip placement="top" title="I do not isFake">
            <ReportOutlined className="icon" onClick={() => handleReaction(3)} />
          </Tooltip>
        }
      </div>
      <div className="itemInfoTop">
        <span>{multimedia.title}</span>
        <span className="limit">+{multimedia.limit}</span>
        <span>{multimedia.year}</span>
      </div>
      <div className="desc">{multimedia.description}</div>
      <div className="genre">{multimedia.gender?.gender}</div>
    </div>
    <Dialog
      open={openModal}
      onClose={handleClose}
      maxWidth={'xl'}
    >
      {multimedia?.mimetypeId === 1 && <>
        <div className="dialogVideo">
          <video
            muted
            ref={refVideo}
            onEnded={handleClose}
            className="video"
            autoPlay={true}
            controls
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
            playsInline
            type="video/mp4"
            src={multimedia?.multimediaId && `${process.env.REACT_APP_URL_API}/multimedia/video/${multimedia?.multimediaId}?token=${token}`}
          />
        </div>
      </>}
    </Dialog>
  </>
}